import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
  sm: '375px',
  md: '768px',
  lg: '1280px',
  xl: '1920px',
  '2xl': '2560px',
})

const theme = extendTheme({
  styles: {
    global: {
      html: {
        backgroundColor: '#002DD1',
      },
      '.chakra-portal-zIndex': {
        zIndex: '600!important',
      },
    },
  },
  textStyles: {
    aktivGrotesk: {
      fontFamily: 'Aktiv Grotesk Medium',
    },
    basisGrotesque: {
      fontFamily: 'Basis Grotesque Mono Pro Medium',
      letterSpacing: '.2em',
    },
    circularXX: {
      fontFamily: 'Circular XX Book',
    },
  },
  fonts: {
    heading: 'Aktiv Grotesk Medium',
    body: 'Circular XX Book',
  },
  breakpoints,
})

export default theme
