import React from 'react'

function ArrowRightIcon({ fill, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="12.793"
      viewBox="0 0 32 12.793"
      {...props}
    >
      <g
        fill={fill || '#fff'}
        data-name="Group 51"
        transform="translate(5527.333 -8789.604)"
      >
        <path
          d="M0 0H31V2H0z"
          data-name="Rectangle 33"
          transform="translate(-5527.333 8795)"
        ></path>
        <path
          d="M0 0H9.05V2.263H0z"
          data-name="Rectangle 34"
          transform="rotate(45 -13360.857 -2246.377)"
        ></path>
        <path
          d="M0 0H9.05V2.262H0z"
          data-name="Rectangle 35"
          transform="rotate(-45 7871.835 11043.51)"
        ></path>
      </g>
    </svg>
  )
}

export default ArrowRightIcon
