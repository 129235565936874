import React from 'react'

function HamburgerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60.088"
      height="16.769"
      data-name="Group 98"
      viewBox="0 0 60.088 16.769"
    >
      <path
        fill="#fff"
        d="M0 0H60.088V2.795H0z"
        data-name="Rectangle 60"
      ></path>
      <path
        fill="#fff"
        d="M0 0H60.088V2.795H0z"
        data-name="Rectangle 61"
        transform="translate(0 6.987)"
      ></path>
      <path
        fill="#fff"
        d="M0 0H26.551V2.795H0z"
        data-name="Rectangle 62"
        transform="translate(33.538 13.974)"
      ></path>
    </svg>
  )
}

export default HamburgerIcon
