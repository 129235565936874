import React from 'react'
import * as Chakra from '@chakra-ui/react'
import DashIcon from './icons/dash-icon'
import { ScrollableLink } from './dynamic-anchors'

const MenuListItem = ({ id, title, onClose }) => {
  const determineActivity = () => {
    if (typeof window !== undefined) {
      return document.title
        .toLowerCase()
        .includes(title.toLowerCase())
    }
  }

  let isActive = determineActivity()

  return (
    //hello world
    <Chakra.ListItem
      d="flex"
      alignItems="center"
      mb={{ lg: '25px', '2xl': '50px' }}
      textStyle="aktivGrotesk"
      onClick={() => (onClose ? onClose() : {})}
    >
      <ScrollableLink href={`/#${title.toLowerCase()}`}>
        <Chakra.Text
          as="span"
          color={isActive && '#002DD1'}
          textStyle="basisGrotesque"
        >
          {id}
        </Chakra.Text>
        <Chakra.ListIcon
          mx={{ base: '14px', md: '56px' }}
          as={DashIcon}
        />
        <Chakra.Text
          color={isActive && '#002DD1'}
          fontSize={{
            base: '22px',
            sm: '26px',
            md: '35px',
            lg: '26px',
            '2xl': '48px',
          }}
          as="span"
          pos="relative"
          transition=" color .2s ease"
          _hover={{
            color: '#002DD1',
            _after: {
              left: '-5%',
              right: 'auto',
              width: 'calc(110%)',
            },
          }}
          _after={{
            content: '""',
            pos: 'absolute',
            zIndex: '1',
            left: '-5%',
            width: 0,
            bottom: '0',
            background: 'rgba(0,45,209,.3)',
            height: '50%',
            transition:
              'width .5s cubic-bezier(0.2,1,0.3,1)',
          }}
        >
          {title}
        </Chakra.Text>
      </ScrollableLink>
    </Chakra.ListItem>
  )
}

export default MenuListItem
