import React, { useEffect } from 'react'
import * as Chakra from '@chakra-ui/react'
import LogoIcon from '../components/icons/logo-icon'
import { uiState } from './state-machines/ui-state'

const Header = ({ isMenuOpen }) => {
  const scrollY = uiState.useGlobalState(
    gs => gs.scrollY
  )
  const logoColor = uiState.useGlobalState(
    gs => gs.logoColor
  )
  const scrollDirection = uiState.useGlobalState(
    gs => gs.scrollDirection
  )

  useEffect(() => {
    if (isMenuOpen) {
      uiState.setState({ logoColor: '#0430D2' })
    }
  }, [isMenuOpen])

  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  return (
    <Chakra.Flex
      zIndex="500"
      h={{ base: '16vh', lg: 'min(152px, 18vh)' }}
      pos="fixed"
      alignItems="center"
      justify="space-between"
      top="0"
      className={
        scrollY > 20 && scrollDirection === 'up'
          ? 'scrolled'
          : undefined
      }
      css={{
        '&.scrolled': {
          transform: 'translateY(0%)',
        },
      }}
      transition="0.5s"
    >
      <Chakra.Flex
        onClick={scrollToTop}
        height="100%"
        pl={{
          base: '40px',
          lg: 'calc(5vw - (50px / 2))',
          xl: 'calc(5vw - (70px / 2))',
          '2xl': 'calc(5vw - (90px / 2))',
        }}
      >
        <LogoIcon
          width={{
            base: '46px',
            lg: '50px',
            xl: '70px',
            '2xl': '90px',
          }}
          fill={logoColor}
          cursor="pointer"
        />
      </Chakra.Flex>
    </Chakra.Flex>
  )
}

export default Header
