import { GlobalState } from 'react-gstate'

class UIStateStore extends GlobalState {
  initialRun = () => {
    if (typeof window !== 'undefined') {
      //window.onresize = this.doResize;
      window.addEventListener(
        'scroll',
        this.listenToBodyScroll
      )
    }
  }

  listenToBodyScroll = () => {
    let localBodyOffset =
      typeof window === 'undefined' || !window.document
        ? 0
        : document.body.getBoundingClientRect()

    uiState.setState({
      bodyOffset: localBodyOffset,
      scrollX: localBodyOffset.left,
      scrollY: -localBodyOffset.top,
      scrollDirection:
        uiState.state.lastScrollTop >
        -localBodyOffset.top
          ? 'down'
          : 'up',
      lastScrollTop: -localBodyOffset.top,
    })
  }

  syntheticScrollTo = (offset, callback) => {
    const fixedOffset = offset.toFixed()
    const onScroll = function () {
      if (
        window.pageYOffset.toFixed() === fixedOffset
      ) {
        window.removeEventListener('scroll', onScroll)
        callback()
      }
    }

    window.addEventListener('scroll', onScroll)
    onScroll()
    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    })
  }

  changeLogoColor = color => {
    uiState.setState({ logoColor: color })
  }
}

export const uiState = new UIStateStore({
  lastScrollTop: 0,
  bodyOffset: 0,
  scrollX: 0,
  scrollY: 0,
  scrollDirection: 'up',
  logoColor: '#fff',
  pageIsChanging: false,
})
