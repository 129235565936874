import React from 'react'

function DashMediumIcon({ fill, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="629.204"
      height="3"
      viewBox="0 0 629.204 3"
      {...props}
    >
      <path
        fill={fill}
        d="M0 0H629.204V3H0z"
        data-name="Rectangle 315"
      ></path>
    </svg>
  )
}

export default DashMediumIcon
