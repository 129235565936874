import React from 'react'

function MenuCloseIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.992"
      height="17.993"
      viewBox="0 0 17.992 17.993"
      {...props}
    >
      <g
        data-name="Group 252"
        transform="translate(4138.871 -7630.705)"
      >
        <path
          d="M0 0H23.778V1.667H0z"
          data-name="Rectangle 36"
          transform="rotate(-135 -476.931 4678.055)"
          fill={props.fill}
        ></path>
        <path
          d="M0 0H23.778V1.667H0z"
          data-name="Rectangle 37"
          transform="rotate(-45 7161.938 8819.818)"
          fill={props.fill}
        ></path>
      </g>
    </svg>
  )
}

export default MenuCloseIcon
