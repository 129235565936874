import React, { useState, useEffect } from 'react'
import * as Chakra from '@chakra-ui/react'
import PageTransition from '../components/animations/page-transition'
import Sidebar from '../components/sidebar'
import Header from '../components/header'
import BgLeft from '../images/bg-left.jpg'
import './style.css'
import { uiState } from '../components/state-machines/ui-state'
import LoadingScreen from '../components/animations/loading-screen'

export default function Layout({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    if (typeof 'window' !== 'undefined') {
      uiState.initialRun()
    }
  }, [])

  return (
    <>
      <Chakra.Flex
        bg="#fff"
        minH="100vh"
        width={{ lg: '100vw' }}
        flexDirection="column"
      >
        <LoadingScreen />

        <Header isMenuOpen={isMenuOpen} />
        <Chakra.Box
          position="fixed"
          bgColor="black"
          bgImage={`url('${BgLeft}')`}
          width="100%"
          height="100%"
          left="0"
          top="0"
          filter="invert(1)"
        />
        <Chakra.Box
          position="fixed"
          bg="#fff"
          width="100%"
          height="100%"
          left="0"
          top="0"
          css={{ mixBlendMode: 'multiply' }}
        />

        <Chakra.Flex flexDirection="column">
          <PageTransition>{children}</PageTransition>
        </Chakra.Flex>

        <Sidebar setIsMenuOpen={setIsMenuOpen} />
      </Chakra.Flex>
    </>
  )
}
