import Manager from './manager'

export const { goToTop } = Manager
export const configureAnchors = Manager.configure

export { setMetaTags } from './utils/meta'
export {
  updateHash as goToAnchor,
  removeHash,
} from './utils/hash'
export {
  default,
  ScrollableLink,
} from './scrollable-section'
