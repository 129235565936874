import React from 'react'
import * as Chakra from '@chakra-ui/react'

export default function FeaturedCompaniesItem({
  title,
  ...props
}) {
  return (
    <Chakra.ListItem {...props}>
      <Chakra.Link
        cursor="none"
        pos="relative"
        _hover={{
          color: '#002DD1',
          _after: {
            left: '-5%',
            right: 'auto',
            width: 'calc(110%)',
          },
        }}
        _after={{
          content: '""',
          pos: 'absolute',
          zIndex: '1',
          left: '-5%',
          width: 0,
          bottom: '0',
          background: 'rgba(76,108,222,.1)',
          height: '50%',
          transition:
            'width .5s cubic-bezier(0.2,1,0.3,1), color .5s ease',
        }}
      >
        {title}
      </Chakra.Link>
    </Chakra.ListItem>
  )
}
