import React from 'react'

function DashIcon({ fill, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="53.778"
      height="1"
      viewBox="0 0 53.778 1"
      {...props}
    >
      <path
        d="M0 0H53.778V2.667H0z"
        data-name="Rectangle 18"
        fill={fill}
      />
    </svg>
  )
}

export default DashIcon
