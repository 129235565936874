import React, { useEffect } from 'react'
import * as Chakra from '@chakra-ui/react'

import HamburgerIcon from '../components/icons/hamburger-icon'
import MenuCloseIcon from '../components/icons/menu-close-icon'
import DashShortIcon from './icons/dash-short-icon'
import DashMediumIcon from './icons/dash-medium-icon'
import DashIcon from './icons/dash-icon'
import MenuListItem from './menu-list-item'
import ArrowRightIcon from '../components/icons/arrow-right-icon'
import MenuImg from '../images/menu-img.jpg'

import { AnimatePresence, motion } from 'framer-motion'
import FeaturedCompaniesItem from './text-layouts/featured-companies-item'
import CustomCursorHover from './custom-cursor-hover'
import { ScrollableLink } from './dynamic-anchors'

const MotionFlex = motion(Chakra.Flex)

const Sidebar = ({ setIsMenuOpen, ...props }) => {
  const {
    isOpen,
    onOpen,
    onClose,
  } = Chakra.useDisclosure()

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden'
      setIsMenuOpen(true)
    } else {
      document.body.style.overflowY = 'scroll'
      setIsMenuOpen(false)
    }
  }, [isOpen])

  return (
    <>
      <Chakra.Flex
        pos="fixed"
        zIndex="10000"
        w={{ base: '120px', lg: 'calc(7vw + 1px)' }}
        bg={{
          base: 'none',
          lg: '#fff',
        }}
        h="100%"
        right="0"
        {...props}
        flexDir="column"
        justify="space-between"
        top="0"
      >
        <CustomCursorHover
          d="flex"
          onClick={() =>
            isOpen ? onClose() : onOpen()
          }
          _hover={{
            lg: { bg: !isOpen && '#002DD1' },
          }}
          paddingTop={{ base: '5vh', lg: '0' }}
          transition="0.3s"
          justifyContent="center"
          bg={{
            base: 'none',
            lg: !isOpen && '#15151D',
          }}
          height={{
            base: '16vh',
            lg: 'min(152px, 18vh)',
          }}
          alignItems="center"
          paddingRight={{
            base: '20px',
            lg: '0',
          }}
        >
          {isOpen ? (
            <MenuCloseIcon />
          ) : (
            <HamburgerIcon />
          )}
        </CustomCursorHover>
        <Chakra.Flex
          d={{
            base: 'none',
            lg: 'flex',
          }}
          mb="5vh"
          flexDirection="column"
          alignItems="center"
          opacity="0.5"
          color="#000"
          fontSize="max(18px, min(1vw, 3vh))"
          transition="0.3s"
          _hover={{
            color: '#002DD1',
            opacity: 1,
          }}
        >
          <DashIcon
            fill="currentColor"
            transform="rotate(90)"
          />
          <ScrollableLink
            as={Chakra.Link}
            href={`/#contact`}
            mt="47px"
            css={{ writingMode: 'vertical-rl' }}
            display={{ lg: 'block' }}
            _hover={{
              textDecoration: 'none',
            }}
          >
            Contact Us
          </ScrollableLink>
        </Chakra.Flex>
      </Chakra.Flex>
      <Chakra.Portal>
        <AnimatePresence>
          {isOpen && (
            <Chakra.Flex
              overflowY="hidden"
              pos="fixed"
              top="0"
              right={{
                base: isOpen ? '60px' : '120px',
                md: '120px',
              }}
              h="100vh"
              bottom="0"
              left="0"
              w="100%"
              zIndex="modal"
            >
              <Chakra.Flex
                flexDirection="row"
                h="100%"
                paddingBottom="0"
                w="100%"
              >
                <Chakra.Flex
                  d={{ base: 'none', lg: 'flex' }}
                  flex="1"
                  w="50%"
                  flexDir="column"
                >
                  <Chakra.Flex h="60%" w="100%">
                    <Chakra.Flex
                      pos="relative"
                      overflow="hidden"
                      zIndex="25"
                      color="#FFFFFF"
                      w="45%"
                    >
                      <MotionFlex
                        w="100%"
                        h="100%"
                        top="100%"
                        left="0"
                        opacity="0"
                        key="motion_1"
                        pos="absolute"
                        animate={{
                          top: '0',
                          opacity: 1,
                        }}
                        exit={{
                          top: '100%',
                          opacity: 0,
                        }}
                        transition={{
                          duration: isOpen ? 0.9 : 0.6,
                          ease: isOpen
                            ? [0.22, 1, 0.36, 1]
                            : [0.76, 0, 0.24, 1],
                        }}
                        bg="#13161D"
                        padding="3vw"
                        alignItems="center"
                      >
                        <Chakra.Flex flexDir="column">
                          <Chakra.Text
                            fontSize="18px"
                            textTransform="uppercase"
                            letterSpacing="0.15em"
                            textStyle="basisGrotesque"
                          >
                            Featured
                            <br /> Companies
                          </Chakra.Text>
                          <DashShortIcon fill="#fff" />
                          <Chakra.UnorderedList
                            fontSize="32px"
                            styleType="none"
                            spacing="0"
                            ml="0"
                            textStyle="activeGrotesk"
                          >
                            <FeaturedCompaniesItem title="AIP Labs" />
                            <FeaturedCompaniesItem title="FanClub" />
                          </Chakra.UnorderedList>
                        </Chakra.Flex>
                      </MotionFlex>
                    </Chakra.Flex>
                    <Chakra.Flex
                      pos="relative"
                      w="55%"
                      overflow="hidden"
                    >
                      <MotionFlex
                        w="100%"
                        h="100%"
                        left="-100%"
                        top="0"
                        opacity="0"
                        key="motion_2"
                        pos="absolute"
                        animate={{
                          left: '0',
                          opacity: 1,
                        }}
                        exit={{
                          left: '-100%',
                          opacity: 0,
                        }}
                        transition={{
                          duration: isOpen ? 0.9 : 0.6,
                          ease: isOpen
                            ? [0.22, 1, 0.36, 1]
                            : [0.76, 0, 0.24, 1],
                        }}
                        bg={`url('${MenuImg}')`}
                        _hover={{
                          transform: 'scale(1.2)',
                        }}
                        bgPosition="top right"
                        css={{
                          transition:
                            'transform .3s ease',
                        }}
                        bgSize="cover"
                      >
                        <Chakra.Flex
                          bg="#1445E5"
                          opacity="0.8"
                          w="100%"
                        />
                      </MotionFlex>
                    </Chakra.Flex>
                  </Chakra.Flex>
                  <Chakra.Flex h="40%">
                    <Chakra.Flex
                      pt="8vh"
                      color="#fff"
                      w="45%"
                      flexDir="column"
                      justify="space-between"
                      pos="relative"
                      overflow="hidden"
                      h="100%"
                    >
                      <MotionFlex
                        w="100%"
                        h="100%"
                        top="0"
                        left="100%"
                        opacity="0"
                        animate={{
                          left: '0',
                          opacity: 1,
                        }}
                        exit={{
                          left: '100%',
                          opacity: 0,
                        }}
                        key="motion_3"
                        pos="absolute"
                        transition={{
                          duration: isOpen ? 0.9 : 0.6,
                          ease: isOpen
                            ? [0.22, 1, 0.36, 1]
                            : [0.76, 0, 0.24, 1],
                        }}
                        bg="#1D2027"
                        flexDir="column"
                        justify="center"
                      >
                        <Chakra.Box
                          padding="3vw"
                          alignItems="center"
                        >
                          <Chakra.Text
                            textTransform="uppercase"
                            textStyle="basisGrotesque"
                          >
                            Inquiries
                          </Chakra.Text>

                          <DashShortIcon fill="#fff" />

                          <Chakra.Text textStyle="aktivGrotesk">
                            hello@techstreamlabs.com
                          </Chakra.Text>
                          <Chakra.Flex mt="3vh">
                            <ArrowRightIcon />
                          </Chakra.Flex>
                        </Chakra.Box>
                      </MotionFlex>
                    </Chakra.Flex>
                    <Chakra.Flex
                      flexDir="column"
                      justify="center"
                      alignItems="center"
                      w="55%"
                      textStyle="aktivGrotesk"
                      pos="relative"
                      overflow="hidden"
                    >
                      <MotionFlex
                        flexDir="column"
                        w="100%"
                        h="100%"
                        top="-100%"
                        left="0"
                        opacity="0"
                        animate={{
                          top: '0',
                          opacity: 1,
                        }}
                        exit={{
                          top: '-100%',
                          opacity: 0,
                        }}
                        key="motion_4"
                        pos="absolute"
                        transition={{
                          duration: isOpen ? 0.9 : 0.6,
                          ease: isOpen
                            ? [0.22, 1, 0.36, 1]
                            : [0.76, 0, 0.24, 1],
                        }}
                        bg="#13161D"
                        p="4"
                        justify="center"
                        alignItems="center"
                        padding="3vw"
                      >
                        <Chakra.Box>
                          <Chakra.Text
                            color="#707070"
                            fontSize="max(18px, 1.5vw)"
                            pb="2.8vh"
                          >
                            "The true meaning of life is
                            to plant trees under whose
                            shade you do not expect to
                            sit."
                          </Chakra.Text>
                          <Chakra.Flex alignItems="center">
                            <Chakra.Text
                              fontSize="max(14px, 1vw)"
                              color="#fff"
                              textTransform="uppercase"
                            >
                              Nelson Henderson
                            </Chakra.Text>
                          </Chakra.Flex>
                        </Chakra.Box>
                      </MotionFlex>
                    </Chakra.Flex>
                  </Chakra.Flex>
                </Chakra.Flex>
                <Chakra.Flex
                  w={{ base: '100%', lg: '50%' }}
                  overflow="hidden"
                  pos="relative"
                >
                  <MotionFlex
                    w="100%"
                    h="100%"
                    top="0%"
                    left="0"
                    key="motion_5"
                    pos="absolute"
                    opacity="0"
                    animate={{ top: '0', opacity: 1 }}
                    exit={{ top: '0%', opacity: 0 }}
                    transition={{
                      duration: isOpen ? 0.5 : 0.4,
                      ease: isOpen
                        ? [0.22, 1, 0.36, 1]
                        : [0.76, 0, 0.24, 1],
                    }}
                    bg="#fff"
                    flexDir="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Chakra.UnorderedList
                      position="relative"
                      zIndex="600"
                      styleType="none"
                      ml="0"
                      width="80%"
                    >
                      <MenuListItem
                        isActive
                        id="01"
                        title="Overview"
                        onClose={onClose}
                      />
                      <MenuListItem
                        id="02"
                        title="About"
                        onClose={onClose}
                      />
                      <MenuListItem
                        id="03"
                        title="Services"
                        onClose={onClose}
                      />
                      <MenuListItem
                        id="04"
                        title="Industries"
                        onClose={onClose}
                      />
                      <MenuListItem
                        id="05"
                        title="Companies"
                        onClose={onClose}
                      />
                      <MenuListItem
                        id="06"
                        title="Leadership"
                        onClose={onClose}
                      />
                      <MenuListItem
                        id="07"
                        title="Contact"
                        onClose={onClose}
                      />
                    </Chakra.UnorderedList>
                    <Chakra.Text
                      pos="absolute"
                      bottom="25px"
                      opacity="0.2"
                      fontSize="18px"
                      textStyle="basisGrotesque"
                      textTransform="uppercase"
                      letterSpacing=".1em"
                    >
                      ©TechStreamLab
                    </Chakra.Text>
                  </MotionFlex>
                </Chakra.Flex>
              </Chakra.Flex>
            </Chakra.Flex>
          )}
        </AnimatePresence>
      </Chakra.Portal>
    </>
  )
}

export default Sidebar
