import React from 'react'

function DashShortIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="32"
      viewBox="0 0 24 32"
    >
      <text
        fill="#fff"
        data-name="—"
        fontFamily="SegoeUI, Segoe UI"
        fontSize="24"
        transform="translate(0 26)"
      >
        <tspan x="0" y="0">
          —
        </tspan>
      </text>
    </svg>
  )
}

export default DashShortIcon
