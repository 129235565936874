import React from 'react'
import * as Chakra from '@chakra-ui/react'

function LogoIcon({ fill, ...props }) {
  return (
    <Chakra.Box
      as="svg"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      data-name="Main Logo"
      viewBox="0 0 90.818 90.987"
      {...props}
    >
      <g data-name="Group 11">
        <path
          style={{ transition: 'fill .4s ease' }}
          fill={fill}
          fillRule="evenodd"
          d="M-5499.678 1652.49v-22l-12.639-7.335v-11.359l22.482 12.971v22.017l9.255 5.2-.1-32.454-42.879-24.82 28.845-16.6 19 10.961 12.64-7.335 9.923 5.719-22.562 12.972-19-10.957-9.176 5.224 28.174 16.3 42.971-24.824v33.362l-19 10.959v14.671l-9.933 5.726v-26.117l19-10.959v-10.571l-28.072 16.292v49.567z"
          data-name="Path 31"
          transform="translate(5523.557 -1578.107)"
        ></path>
      </g>
    </Chakra.Box>
  )
}

export default LogoIcon
