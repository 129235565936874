import React from 'react'
import * as Chakra from '@chakra-ui/react'
import { motion } from 'framer-motion'
import LoadingLogoBigIcon from '../icons/loading-logo-big-icon'

const MotionFlex = motion(Chakra.Flex)

export default function LoadingScreen(props) {
  return (
    <>
      <MotionFlex
        animate={{ y: '-100%' }}
        transition={{ delay: 3, duration: 0.6 }}
        pos="fixed"
        width="100%"
        h="100vh"
        bg="#fff"
        zIndex="50000"
        justify="center"
        alignItems="center"
      >
        <MotionFlex
          animate={{ y: '0' }}
          transition={{ delay: 3, duration: 1 }}
          pos="fixed"
          w="100%"
          h="100%"
          bg="#002DD1"
          justify="center"
          alignItems="center"
        >
          <MotionFlex
            opacity="0"
            animate={{ opacity: 1 }}
            transition={{ delay: 0, duration: 1 }}
            width="75vw"
            maxW="965px"
          >
            <LoadingLogoBigIcon />
          </MotionFlex>
        </MotionFlex>
      </MotionFlex>
    </>
  )
}
