import React from 'react'

function LoadingLogoBigIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="964.698"
      height="122.898"
      viewBox="0 0 964.698 122.898"
    >
      <g
        data-name="Group 82"
        transform="translate(152.487 16.116)"
      >
        <g
          data-name="Group 79"
          transform="translate(782.695 49.436)"
        >
          <path
            fill="#fff"
            d="M-5151.705 1631.9a14.755 14.755 0 01-14.727-14.787 14.689 14.689 0 0114.792-14.791 14.8 14.8 0 0114.725 14.791 14.727 14.727 0 01-14.79 14.787zm.064-26.546a11.629 11.629 0 00-11.631 11.758 11.629 11.629 0 0011.567 11.818 11.721 11.721 0 0011.629-11.818 11.654 11.654 0 00-11.565-11.758zm-1.9 20.163h-4.489v-17.129h7.523a8.037 8.037 0 013.414.7 4.619 4.619 0 012.59 4.425 5.172 5.172 0 01-3.541 5.245l4.109 6.763h-5.119l-3.476-6.067h-1.011zm0-9.67h1.958c1.707 0 2.593-.633 2.593-1.9 0-1.454-1.013-1.9-2.845-1.9h-1.706z"
            data-name="Path 81"
            transform="translate(5166.432 -1602.321)"
          ></path>
        </g>
        <g fill="#fff" data-name="Group 80">
          <path
            d="M-5431.775 1604.317h-18.977v-11.978h51.424v11.978h-18.98v63.832h-13.467z"
            data-name="Path 82"
            transform="translate(5450.752 -1590.641)"
          ></path>
          <path
            d="M-5419.466 1631.781c.848 9.118 7 15.693 16.014 15.693 6.362 0 10.284-2.649 12.722-6.891h13.043c-3.4 10.178-12.62 17.6-26.086 17.6-17.388 0-28.627-12.3-28.627-29.687 0-16.963 11.451-29.582 28.415-29.582 17.708 0 27.252 13.251 27.252 29.474 0 2.017-.108 3.185-.108 3.5zm29.794-9.224c-.845-7.634-6.041-13.573-14.417-13.573-8.06 0-13.787 4.666-14.95 13.573z"
            data-name="Path 83"
            transform="translate(5483.303 -1578.978)"
          ></path>
          <path
            d="M-5355.085 1638.671c-3.186 11.135-11.879 19.509-26.193 19.509-17.6 0-28.416-12.507-28.416-29.582 0-16.857 11.345-29.687 28.627-29.687 14.422 0 23.539 8.588 26.085 20.355h-13.467c-2.225-5.936-6.042-9.539-12.83-9.539-9.329 0-15.161 7.844-15.161 18.657 0 11.454 5.833 19.088 15.694 19.088a13.179 13.179 0 0012.935-8.8z"
            data-name="Path 84"
            transform="translate(5523.588 -1578.978)"
          ></path>
          <path
            d="M-5373.169 1607.712c0 4.136-.106 6.785-.212 11.027 3.5-4.663 8.694-8.058 16.857-8.058 12.831 0 20.147 8.9 20.147 22.586v34.882h-12.83v-28.628c0-11.03-2.546-18.025-11.344-18.025s-12.619 6.574-12.619 15.9v30.749h-12.93v-75.81h12.935z"
            data-name="Path 85"
            transform="translate(5565.43 -1590.641)"
          ></path>
          <path
            d="M-5351.348 1647.708c1.588 7.213 7.211 11.771 16.116 11.771s13.678-4.663 13.678-11.452c0-7-5.408-8.8-13.892-11.026l-4.979-1.376c-13.361-3.712-21.525-10.075-21.525-22.162 0-12.616 11.025-21.737 26.293-21.737 15.588 0 25.025 8.588 26.194 21.31h-12.83c-1.592-6.042-5.728-9.648-13.683-9.648-7.634 0-11.871 3.925-11.871 9.332 0 6.466 5.3 8.375 13.355 10.918l5.3 1.6c13.466 4.241 21.525 8.48 21.525 21.947 0 14.1-11.555 23.643-27.993 23.643-16.22 0-27.882-9.118-28.731-23.115z"
            data-name="Path 86"
            transform="translate(5603.952 -1591.726)"
          ></path>
          <path
            d="M-5320.483 1609.229h10.389v9.648h-10.389v30.431c0 5.2 2.439 6.89 6.676 6.89a17.979 17.979 0 002.652-.21v9.542a18.964 18.964 0 01-4.981.424c-10.5 0-17.284-3.392-17.284-14.419v-32.658h-8.375v-9.648h8.375v-15.057h12.937z"
            data-name="Path 87"
            transform="translate(5644.034 -1587.386)"
          ></path>
          <path
            d="M-5314.542 1600.375v3.817c0 1.271 0 2.544-.1 3.709 2.011-5.618 7.1-8.8 13.465-8.8a20.418 20.418 0 013.074.211v12.3a13.194 13.194 0 00-3.708-.422c-10.181 0-11.88 7.21-11.88 18.66v26.295h-12.933v-45.7c0-3.5 0-6.68-.107-10.072z"
            data-name="Path 88"
            transform="translate(5670.757 -1578.638)"
          ></path>
          <path
            d="M-5301.681 1631.781c.85 9.118 7 15.693 16.014 15.693 6.362 0 10.284-2.649 12.724-6.891h13.042c-3.4 10.178-12.618 17.6-26.082 17.6-17.391 0-28.632-12.3-28.632-29.687 0-16.963 11.452-29.582 28.417-29.582 17.708 0 27.252 13.251 27.252 29.474 0 2.017-.1 3.185-.1 3.5zm29.8-9.224c-.849-7.634-6.045-13.573-14.423-13.573-8.058 0-13.784 4.666-14.948 13.573z"
            data-name="Path 89"
            transform="translate(5692.253 -1578.978)"
          ></path>
          <path
            d="M-5289.448 1618.141c.53-10.708 9.33-19.19 23.855-19.19 13.574 0 23.007 5.62 23.007 24.6v15.587c0 7.739.427 13.148 1.274 17.282h-11.874a39.083 39.083 0 01-.635-7.423c-3.079 4.561-9.439 8.483-18.452 8.483-11.559 0-19.825-6.044-19.825-16.541 0-11.556 12.406-16.012 24.176-17.706 4.768-.741 9.118-1.165 12.83-1.484-.322-9.329-3.5-12.724-10.819-12.724s-10.5 3.712-11.237 9.118zm34.566 12.619l-2.861.316c-14.1 1.379-20.569 3.712-20.569 9.545 0 5.09 3.708 7.634 9.647 7.634 9.329 0 13.677-5.725 13.677-15.162 0-.106 0-1.166.106-2.334z"
            data-name="Path 90"
            transform="translate(5732.2 -1578.911)"
          ></path>
          <path
            d="M-5256.756 1604.251c0 1.165 0 2.228-.106 3.5 2.862-4.985 8.375-8.8 16.752-8.8 7.954 0 13.36 3.4 16.647 9.437 3.394-4.771 8.907-9.437 18.77-9.437 12.828 0 20.038 8.163 20.038 22.9v34.567h-12.935v-29.687c0-11.453-3.073-16.966-10.71-16.966-8.06 0-12.09 6.042-12.09 14.419v32.234h-12.935v-30.006c0-11.346-3.073-16.647-10.812-16.647-8.482 0-11.981 6.68-11.981 15.587v31.066h-12.937v-47.08c0-2.968 0-5.617-.107-8.586h12.407z"
            data-name="Path 91"
            transform="translate(5772.892 -1578.911)"
          ></path>
          <path
            d="M-5232.957 1592.339h13.468v64.039h30.431v11.77h-43.9z"
            data-name="Path 92"
            transform="translate(5837.115 -1590.641)"
          ></path>
          <path
            d="M-5212.221 1618.141c.531-10.708 9.329-19.19 23.856-19.19 13.574 0 23.008 5.62 23.008 24.6v15.587c0 7.739.424 13.148 1.273 17.282h-11.876a39.2 39.2 0 01-.638-7.423c-3.073 4.561-9.435 8.483-18.448 8.483-11.558 0-19.827-6.044-19.827-16.541 0-11.556 12.4-16.012 24.175-17.706 4.772-.741 9.118-1.165 12.83-1.484-.318-9.329-3.5-12.724-10.815-12.724s-10.5 3.712-11.24 9.118zm34.563 12.619l-2.861.316c-14.1 1.379-20.569 3.712-20.569 9.545 0 5.09 3.711 7.634 9.65 7.634 9.328 0 13.675-5.725 13.675-15.162.001-.106.001-1.166.106-2.334z"
            data-name="Path 93"
            transform="translate(5869.193 -1578.911)"
          ></path>
          <path
            d="M-5191.9 1592.339h12.936v16.222c0 3.817 0 7.1-.107 11.024a22.077 22.077 0 0118.236-8.9c16.117 0 26.087 12.616 26.087 29.476 0 17.39-10.5 29.266-25.767 29.266-9.648 0-15.906-4.136-19.19-9.118 0 1.376.105 2.755.105 4.028v3.817h-12.3zm12.4 47.607c0 10.921 5.83 18.98 16.012 18.98 9.752 0 15.478-7.953 15.478-18.558s-5.726-18.976-15.478-18.976c-9.325-.001-16.004 7.843-16.004 18.553z"
            data-name="Path 94"
            transform="translate(5909.952 -1590.641)"
          ></path>
        </g>
      </g>
      <g data-name="Group 121">
        <g data-name="Group 11">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M-5491.3 1678.578v-29.721l-17.071-9.907v-15.343l30.367 17.521v29.739l12.5 7.023-.134-43.836-57.917-33.525 38.961-22.42 25.67 14.805 17.073-9.907 13.4 7.725-30.476 17.522-25.664-14.8-12.395 7.056 38.056 22.01 58.042-33.531v45.063l-25.667 14.8v19.817l-13.417 7.735v-35.277l25.667-14.8v-14.278l-37.918 22.007v66.951z"
            data-name="Path 31"
            transform="translate(5523.555 -1578.107)"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default LoadingLogoBigIcon
