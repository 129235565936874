import React from 'react'
import * as Chakra from '@chakra-ui/react'

const CustomCursorHover = ({ children, ...props }) => {
  if (
    Array.isArray(children) ||
    (props && Object.keys(props).length !== 0)
  ) {
    return (
      <Chakra.Box
        cursor="pointer"
        onMouseEnter={() => {
          //const cursor = document.querySelector('.cursor')
          //cursor.classList.add('hover')
        }}
        onMouseLeave={() => {
          //const cursor = document.querySelector('.cursor')
          //cursor.classList.remove('hover')
        }}
        {...props}
      >
        {children}
      </Chakra.Box>
    )
  } else {
    return React.cloneElement(children, {
      onMouseEnter: () => {
        //const cursor = document.querySelector('.cursor')
        //cursor.classList.add('hover')
      },
      onMouseLeave: () => {
        //const cursor = document.querySelector('.cursor')
        //cursor.classList.remove('hover')
      },
    })
  }
}

export default CustomCursorHover
